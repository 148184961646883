<template>
  <div class="supplier-cost-pop common-pop">
    <el-dialog
      :title="todo == 'view' ? '【查看】费用信息' : '【编辑】费用信息'"
      :close-on-click-modal="false"
      width="80%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="96px" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="客户名称">
                <el-input v-model="dataForm.customerName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车型" prop="vehicleType">
                <el-select class="item-choose" v-model="dataForm.vehicleType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50" v-for="(item) in dataForm.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>客户维护费</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="costInfo" ref="dataForm" :rules="rules" label-width="100px" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="运费">
                <el-input v-model="costInfo.auditTotalMoney" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="维护率(%)">
                <el-input v-model="dataForm.maintainFeePercentage" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="维护费">
                <el-input v-model="dataForm.maintainFee" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="freightFee" >
                <el-input v-model="costInfo.maintainOtherFee" placeholder="其他费用"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col>
              <el-form-item label="备注">
                <el-input :disabled="isViews" v-model="costInfo.maintainRemark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo !== 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {
          orderGoods: {}
        },
        vehiclePlan: {}
      },
      costInfoData: {},
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      todo: 'view',
      costInfo: {}
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view' || this.dataForm.maintenanceFeeWriteStatus === 1
    }
  },
  methods: {
    init (type, todo, row) {
      this.visible = true
      this.todo = todo || 'view'
      this.dataForm = row
      if (!this.dataForm.orderGoods) {
        this.dataForm.orderGoods = {}
      }
      this.costInfoData = JSON.parse(JSON.stringify(row))
      this.$http({
        url: this.$http.adornUrl(`/costInfo/maintainFeeCost/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.costInfo = data.costInfo
        this.dataForm.takeGoodsDate = data.takeGoodsDate + ' ' + data.takeGoodsTime
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/costInfo/update/maintainOtherFee`),
            method: 'POST',
            data: this.$http.adornData({
              'id': this.dataForm.costInfo.id,
              'maintainOtherFee': this.dataForm.costInfo.maintainOtherFee,
              'maintainRemark': this.dataForm.costInfo.maintainRemark
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
.supplier-cost-pop {
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-dialog {
    // margin-top: 20px !important;
    .el-input__inner {
      height: 26px !important;
    }
    .el-dialog__header {
      padding: 10px 20px 10px;
    }
  }
  .el-dialog__body {
    padding: 0;
    max-height: 650px;
    overflow: auto;
  }
  .el-dialog__footer {
    padding: 10px 20px 10px;
    .el-button {
      height: 32px;
      line-height: 0;
    }
  }
  .modularBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;
    .el-col {
      padding-right: 0 !important;
    }
  }
  .modularBox1 {
    padding-right: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .modularBox2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 40px;
    margin-bottom: 2px;
    .addressBtn {
      width: 100%;
      padding: 9px 0px;
      text-align: center
    }
    .deleteBtn {
      width: 100%;
      height: 40px;
      padding-top: 5px;
      text-align: right;
    }
  }
  .modularBox3 {
    padding: 25px;
    .carBox {
      width: 320px;
      margin-right: 20px;
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      text-align: center;
      cursor: pointer;
    }
  }
  .modularBox4 {
    padding: 25px;
  }
  .modularLabel {
    margin-bottom: 5px;
    padding-left: 20px;
    margin-top: 0px;
    span.separate {
      display: inline-block;
      width: 5px;
      margin-right: 10px;
      background: #17B3A3;
      color: transparent;
      height: 20px;
      line-height: 20px;
    }
    label {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .modularLabel2 {
    margin-top: 10px;
    line-height: 30px;
    height: 30px;
    label {
      min-width: 120px;
      float: left;
    }
  }
  .footer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    text-align: right;
    padding: 25px;
    .explain {
      color: #17B3A3 !important
    }
  }
}
</style>
